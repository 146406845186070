import React, { useEffect, useState } from 'react';
// import Papa from 'papaparse';
// import { LineGraph } from './goal-control/LineGraph.js';
// import { REACT_APP_URL_FORECASTING, REACT_APP_URL_BP, REACT_APP_URL_COSTS_BUDGETED, REACT_APP_URL_COSTS } from '../../config';

const DRE = () => {
    // const [data, setData] = useState([]);
    // const [goal, setGoal] = useState([]);


    // useEffect(() => {
    //     const urls = [REACT_APP_URL_FORECASTING, REACT_APP_URL_BP, REACT_APP_URL_COSTS, REACT_APP_URL_COSTS_BUDGETED]; // Add your URLs here
    //     console.log('urls --> ', urls)
    //     const fetchData = async () => {
    //         try {
    //             const responses = await Promise.all(
    //                 urls.map(url => fetch(url).then(response => response.text()))
    //             );
    //             console.log('responses --> ', responses)
    //             let combinedData = [];
    //             for (const csv of responses) {
    //                 Papa.parse(csv, {
    //                     header: true,
    //                     skipEmptyLines: true,
    //                     complete: (result) => {
    //                         combinedData = combinedData.concat(result.data);
    //                     }
    //                 });
    //             }
    //             setData(combinedData);
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //             // Handle errors here
    //         }
    //     };

    //     fetchData();
    // }, []);

    return (
        <div>
            DRE
            {/* {data.map((row, index) => (
                <div key={index}>{JSON.stringify(row)}</div> // Customize how you display the data
            ))} */}
        </div>
    );
};

export default DRE;
