import React, { useState, useEffect } from 'react';
import { Container, Tab, Tabs, Paper, Box, Stepper, useThemeProps } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Pipeline from './Pipeline'


function ConversionRates(props) {
    const [timespam, setTimespam] = React.useState("yearly");
    const [value, setValue] = React.useState(0)

    const searched = props.searched
    const result = props.result

    const handleChangeTimespam = (event) => {
        setTimespam(event.target.value);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Container marginRight="3%"  maxWidth={true}>
            <div align="center">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-helper-label">INTERVALO</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={timespam}
                        label="INTERVALO"
                        onChange={handleChangeTimespam}
                    >
                        <MenuItem value="yearly">ANUAL</MenuItem>
                        <MenuItem value="halfYearly">SEMESTRAL</MenuItem>
                        <MenuItem value="quarterly">TRIMESTRAL</MenuItem>
                        <MenuItem value="monthly">MENSAL</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-helper-label">PIPELINE</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={value}
                        label="PIPELINE"
                        onChange={handleChange}
                    >
                        <MenuItem value={0}>SOLVE INBOUND PIPELINE</MenuItem>
                        <MenuItem value={1}>SOLVE OUTBOUND PIPELINE</MenuItem>
                        <MenuItem value={2}>SOLVE VENDA DE IMÓVEIS PIPELINE</MenuItem>
                    </Select>
                </FormControl>


                {searched ? 
                    <Pipeline lineData={result[value][timespam]} pipeline={value} setup={false}/>
                    :null
                }
            </div>
        </Container>
    );
}

export default ConversionRates;
