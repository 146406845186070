import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import DualYAxisLineGraph from './DualYAxisLineGraph'

function setSteps(pipeline){
    if(pipeline == 0)
    {
        return [
            'OPORTUNIDADE INBOUND',
            'Lead Quente (há interesse) INBOUND',
            'FORMULÁRIO INBOUND',
            'DUE DILIGENCE INBOUND',
            'PROPOSTA FEITA INBOUND',
            'CONTRATO ASSINADO INBOUND'
        ]
    }
    else if(pipeline == 1)
    {
        return [
            'PRÉ-LEADS',
            'Lead pré-qualificado',
            'LIGAÇÃO REALIZADA',
            'OPORTUNIDADE',
            'REUNIÃO MARCADA',
            'Lead Quente (há interesse)',
            'FORMULÁRIO',
            'DUE DILIGENCE',
            'PROPOSTA FEITA',
            'CONTRATO ASSINADO'
        ]
    }
    else
    {
        return [
            'CONTRATO ASSINADO VENDAS',
            'ENVIO DE FORMULÁRIO',
            'PRODUÇÃO DE FOTOS',
            'ENVIO AOS PARCEIROS',
            'ANUNCIADO',
            'VISITA',
            'PROPOSTA',
            'NEGOCIAÇÃO',
            'VENDIDO',
            'FATURADO',
            'RECEBIDO'        
        ]
    }
}

function transformLineData(lineData, steps)
{
    const result = {}
    const periods = Object.keys(lineData)
    periods.forEach(period => {
        const conversionRates = lineData[period].conversionRates
        const stageVolumes = lineData[period].stageVolumes
        steps.forEach(step => {
            if(!result[step])
            {
                result[step] = []
            }
            var conversionRate = conversionRates[step]
            var stageVolume = stageVolumes[step]
            result[step].push({index: period, vol: stageVolume, convRate: conversionRate})
        })
    })
    return result
}

function Pipeline(props) {
    const [pipeline, setPipeline] = React.useState(props.pipeline);
    const steps = setSteps(props.pipeline)
    const [activeStep, setActiveStep] = React.useState(props.activeStep);
    const [stageName, setStageName] = React.useState(steps[0])
    const lineData = transformLineData(props.lineData, steps)

    const handleStep = (step, stage) => () => {
        setPipeline(props.pipeline)
        setActiveStep(step);
        setStageName(stage)
    };

    const setBorder = (index) => {
        return index == activeStep ? 2:0
    }

    return (
        <Box sx={{ width: '100%'}}>
            <Stepper nonLinear activeStep={activeStep} style={{'marginRight':'2%', 'marginBottom':'1%'}}>
                {steps.map((stage, index) => (
                    <Step key={index}>
                        <br/>
                        <div align="center" style={{ marginLeft: '5%' }}>
                                {stage.replace('INBOUND', '')}
                        </div>
                        <br/>
                    <StepButton onClick={handleStep(index, stage)} sx={{borderColor: 'info.main', border: setBorder(index), 
                                                                        boxShadow: 1, borderRadius: 2, margin: 0.1}}>
                    </StepButton>
                    </Step>
                ))}
            </Stepper>
            {pipeline == props.pipeline ?
                <div align="center">
                    <DualYAxisLineGraph data={lineData[stageName]} />
                </div>
                :null
            }
        </Box>
    );
}

export default Pipeline;