import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Grid, Typography, Select, MenuItem, InputLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import LoadingButton from '@mui/lab/LoadingButton';
import api from '../../api/api'

const ClosedDealsGoal = () => {
    const [year, setYear] = useState(new Date().getFullYear())
    const [percentInbound, setPercentInbound] = useState(50)
    const [percentOutbound, setPercentOutbound] = useState(50)
    const [loading, setLoading] = React.useState(false);

    const years = [2022, 2023, 2024]

    useEffect(() => {
        async function fetchClosedDeals()
        {
            var result = await api.get('history/get-closed-deals')
            result = result.data
            const newState = result.reduce((acc, goal) => {
                if (!acc[goal.year]) {
                    acc[goal.year] = {};
                }
                acc[goal.year][goal.month] = goal.closed_deals;
                return acc;
            }, {});
            
            // Step 2: Translation map for month numbers to string.
            const monthMap = {
                0: 'JAN',
                1: 'FEV',
                2: 'MAR',
                3: 'ABR',
                4: 'MAI',
                5: 'JUN',
                6: 'JUL',
                7: 'AGO',
                8: 'SET',
                9: 'OUT',
                10: 'NOV',
                11: 'DEC'
            };
            
            // Translate the numeric months to their string representations.
            for (const year in newState) {
                for (const month in newState[year]) {
                    newState[year][monthMap[month]] = newState[year][month];
                    delete newState[year][month];
                }
            }
            // Step 3: Set the new state using setGoals.
            setGoals(prevGoals => ({
                ...prevGoals,
                ...newState
            }));
        }
        fetchClosedDeals()
    }, []);

    const [goals, setGoals] = useState({
        2022: {
                JAN: 0,
                FEV: 0,
                MAR: 0,
                ABR: 0,
                MAI: 0,
                JUN: 0,
                JUL: 0,
                AGO: 0,
                SET: 0,
                OUT: 0,
                NOV: 0,
                DEC: 0
            },
        2023: {
                JAN: 0,
                FEV: 0,
                MAR: 0,
                ABR: 0,
                MAI: 0,
                JUN: 0,
                JUL: 0,
                AGO: 0,
                SET: 0,
                OUT: 0,
                NOV: 0,
                DEC: 0
            },
        2024: {
                JAN: 0,
                FEV: 0,
                MAR: 0,
                ABR: 0,
                MAI: 0,
                JUN: 0,
                JUL: 0,
                AGO: 0,
                SET: 0,
                OUT: 0,
                NOV: 0,
                DEC: 0
            },
    });

    useEffect(() => {
    }, [goals])

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setGoals(prevGoals => ({
            ...prevGoals,
            [year]: {
                ...prevGoals[year],
                [name]: value,
            }
        }));
    };

    function hasMonthStarted(year, month)
    {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const startOfMonth = new Date(year, month, 1); // month is zero-based in Date

        return currentDate > startOfMonth;
    }

    async function updateGoals()
    {
        setLoading(true)
        var goalList = Object.keys(goals[year])
        goalList.forEach(async (goal, index) => {
            await api.post('history/insert-closed-deals', {
                goal: goals[year][goal],
                month: index,
                year: year
            })
        })
        await api.post('history/update-current-monthly-goals')
        setLoading(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const handlePercentInbound = (event) => {
        setPercentInbound(event.target.value);
        setPercentOutbound(100-event.target.value);

    };

    const handlePercentOutbound = (event) => {
        setPercentOutbound(event.target.value);
        setPercentInbound(100-event.target.value);
    };

    return (
        <Container>
            <div style={{'width': "100%", 'float': "left"}}>
                <Typography variant="h4" gutterBottom>
                    Metas mensais {year}
                </Typography>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-helper-label">ANO</InputLabel>
                    <Select
                        labelId="year-label"
                        value={year}
                        onChange={handleYearChange}
                        label="ANO"
                    >
                        {years.map((y) => (
                            <MenuItem key={y} value={y}>
                            {y}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <TextField
                        type="number"
                        label="Percentual INBOUND(%)"
                        name={percentInbound}
                        value={percentInbound}
                        onChange={handlePercentInbound}
                        variant="outlined"
                    />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <TextField
                        type="number"
                        label="Percentual OUTBOUND(%)"
                        name={percentOutbound}
                        value={percentOutbound}
                        onChange={handlePercentOutbound}
                        variant="outlined"
                    />
                </FormControl>

            </div>
            <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                {Object.keys(goals[year]).map((month, monthIndex) => (
                    <Grid item xs={12} sm={6} key={month}>
                        <TextField
                            disabled={hasMonthStarted(year, monthIndex)}
                            fullWidth
                            type="number"
                            label={month}
                            name={month}
                            value={goals[year][month]}
                            onChange={handleInputChange}
                            variant="outlined"
                        />
                    </Grid>
                ))}
            </Grid>
            <LoadingButton loading={loading} onClick={updateGoals} type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
                ATUALIZAR METAS
            </LoadingButton>
            </form>
        </Container>
    );
};

export default ClosedDealsGoal;
