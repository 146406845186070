import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SalesPipelines from './pages/operations/SalesPipelines';
import History from './pages/operations/History';
import Login from './pages/Login';
import Finance from './pages/finance/Finance';
import Marketing from './pages/marketing/Marketing';
import ChooseDashboard from './pages/ChooseDashboard';

function App() {
    const [loggedIn, setLoggedIn] = React.useState(false)

    const authenticate = (authenticated) => {
        setLoggedIn(authenticated)
        localStorage.setItem('authenticated', authenticated)
    }

    useMemo(() => {
        const loggedInUser = localStorage.getItem("authenticated");
        setLoggedIn(loggedInUser)
    }, []);

    return (
        <div>
            {loggedIn ? 
                <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<SalesPipelines />}/>
                        <Route path='/history' element={<History />}/>
                        <Route path='/finance' element={<Finance />}/>
                        <Route path='/marketing' element={<Marketing />}/>
                    </Routes>
                </BrowserRouter>
                :
                <Login authenticate={authenticate} />
            }
        </div>
    );
}

export default App;