const REACT_APP_API_KEY = 'http://localhost:3001'
const REACT_APP_URL_FORECASTING='https://docs.google.com/spreadsheets/d/e/2PACX-1vSEL3_qvwpb8ZUjHJ8-Om_pQ0uCQgJV6JRtgmb-_8rK6uDInmI3RWjTFlOmhIKe8w/pub?gid=1058982317&single=true&output=csv'
const REACT_APP_URL_BP='https://docs.google.com/spreadsheets/d/e/2PACX-1vSEL3_qvwpb8ZUjHJ8-Om_pQ0uCQgJV6JRtgmb-_8rK6uDInmI3RWjTFlOmhIKe8w/pubhtml?gid=1251988568&single=true'
const REACT_APP_URL_COSTS_BUDGETED='https://docs.google.com/spreadsheets/d/e/2PACX-1vSEL3_qvwpb8ZUjHJ8-Om_pQ0uCQgJV6JRtgmb-_8rK6uDInmI3RWjTFlOmhIKe8w/pub?gid=508322624&single=true&output=csv'
const REACT_APP_URL_COSTS='https://docs.google.com/spreadsheets/d/e/2PACX-1vSEL3_qvwpb8ZUjHJ8-Om_pQ0uCQgJV6JRtgmb-_8rK6uDInmI3RWjTFlOmhIKe8w/pub?gid=156215625&single=true&output=csv'


export { 
    REACT_APP_API_KEY,
    REACT_APP_URL_FORECASTING,
    REACT_APP_URL_BP,
    REACT_APP_URL_COSTS_BUDGETED,
    REACT_APP_URL_COSTS
}