import React, { useState, useEffect } from 'react';
import { Container, Box, Select, InputLabel, Paper } from '@mui/material';
import { Tab } from '@mui/material';
import {TabContext, LoadingButton, TabList,TabPanel} from '@mui/lab';
import Cashflow from '../../components/finance/Cashflow';
import Costs from '../../components/finance/Costs';
import DRE from '../../components/finance/DRE';

function Finance() {

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <Container style={{maxWidth: "100%"}}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} variant="fullWidth" aria-label="finance tabs">
                        <Tab label="CAIXA" value="1" sx={{ fontSize: '1.2rem' }} />
                        <Tab label="CUSTOS" value="2" sx={{ fontSize: '1.2rem' }} />
                        <Tab label="DRE" value="3" sx={{ fontSize: '1.2rem' }} />
                    </TabList>
                </Box>

                <div>
                    <TabPanel value="1"><Cashflow /></TabPanel>
                    <TabPanel value="2"><Costs /></TabPanel>
                    <TabPanel value="3"><DRE /></TabPanel>
                </div>
            </TabContext>
        </Container>
    );
}

export default Finance;
