import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { LineGraph } from '../goal-control/LineGraph.js';
import { REACT_APP_URL_FORECASTING, REACT_APP_URL_BP } from '../../config';

const Cashflow = () => {
    const [goal, setGoal] = useState([]);

    async function getData(URL, rowName) {
        var response = await fetch(URL);
        var data = await response.text();
        Papa.parse(data, {
            header: true, // Set to true if your data has column headers
            complete: (result) => {
                console.log(result.data); // Check the parsed data
                for (const row of result.data) {
                    if (row['CENÁRIO SOMENTE SUB-ROGAÇÃO'] === rowName) {
                        return row;
                    }
                }
            }
        });
    }

    useEffect(async () => {
        var forecasting = await getData(REACT_APP_URL_FORECASTING, 'CAIXA DA SOLVE');
        console.log('forecasting --> ', forecasting)
        var bp = await getData(REACT_APP_URL_BP, 'CAIXA DA SOLVE');
        console.log('bp --> ', bp)
    }, []);

    return (
        <div>
            CASHFLOW
            {/* {data.map((row, index) => (
                <div key={index}>{JSON.stringify(row)}</div> // Customize how you display the data
            ))} */}
        </div>
    );
};

export default Cashflow;
