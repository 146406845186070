import React from 'react';
import { LineChart, Line, Label, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ReferenceLine } from 'recharts';

function LineGraph(props) {
  // Example data
  const data = props.data
  const maxRight = Math.ceil(Math.max(...data.map(item => item.feito))*1.1)
  const maxLeft = Math.ceil(Math.max(...data.map(item => item.meta))*1.1);
  const max = maxLeft > maxRight ? maxLeft:maxRight

  const totalDone = data.slice(0, 5).reduce((acc, curr) => acc + curr.feito, 0);
  const averageDone = totalDone / data.slice(0, 5).length;

  return (
    <LineChart
        style={{marginTop: "5%"}}
        width={800}
        height={400}
        data={data}
        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
    >
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis dataKey="index" />
        <YAxis domain={[0, max]}>
            <Label value={`Média: ${averageDone}`} offset={20} position='insideBottom' stroke="blue"/>
        </YAxis>
        <Tooltip />
        <Legend  wrapperStyle={{ fontSize: '18px' }}/>
        <Line type="monotone" dataKey="meta" stroke="#8884d8" strokeWidth={4} activeDot={{ r: 8 }}>
            <LabelList dataKey="meta" position="top" stroke="#8884d8" offset={10}/>
        </Line>
        <Line type="monotone" dataKey="feito" stroke="#82ca9d" strokeWidth={4}>
            <LabelList dataKey="feito" position="top" stroke="#82ca9d" offset={10}/>
        </Line>
        <ReferenceLine x={data[4].index} stroke="red" strokeDasharray="2 2"/>
        <ReferenceLine y={averageDone} stroke="blue" strokeWidth={3} strokeDasharray="1 1" /> {/* Vertical line at midpoint */}

    </LineChart>
  );
}

export default LineGraph;
