import React, { useState, useEffect } from 'react';
import { Container, Typography, Tab, Tabs, Paper, Box, Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import PipelinePanel from '../../components/goal-control/PipelinePanel';
import LoadingButton from '@mui/lab/LoadingButton';
import api from '../../api/api'

function buildStages(goal, data, week, month) {
    const inbound = [];
    const outbound = [];
    const venda = [];

    console.log('inside build stages')
    console.log('goal: ', goal)
    console.log('data: ', data)
    console.log('week: ', week)
    console.log('month: ', month)

    for (const [k, value] of Object.entries(goal)) {
        var key = k.replace('col_', '')
        const stageName = getStageName(key)
        if(week ==0){
            goal = value
        }
        else {
            goal = Math.round(value/4)
        }
        var done = null
        
        if (/^\d+$/.test(key))
        {
            done = data[('column_'+key)] || 0;
        }
        else
        {
            done = data[key] || 0;
        }

        const stageObject = { stage: stageName, done, goal };

        if (key.startsWith("267711") || key === "28853651")
        {
            inbound.push(stageObject);
        } else if (key.startsWith("182789") || key.startsWith("96967535") || key.startsWith("contractsent") || key.startsWith("closedwon") || key.startsWith("presentationscheduled") || key.startsWith("decisionmakerboughtin") || key.startsWith("qualifiedtobuy") || key.startsWith("appointmentscheduled") || key.startsWith("86031677") || key.startsWith("16545556")) {
            outbound.push(stageObject);
        } else if (key.startsWith("71310377") || key.startsWith("766194") || key.startsWith("767018")) {
            venda.push(stageObject);
        }
    }

    console.log('inbound: ', inbound)
    console.log('outbound: ', outbound)
    console.log('venda: ', venda)

    return { inbound, outbound, venda };
}

function getFirstDayOfWeek(weekNumber, year) {
    const date = new Date(year, 0, 1 + (weekNumber - 1) * 7);
    const day = date.getDay();
    const dayOfWeekStart = 1; // 0 for Sunday, 1 for Monday, etc.

    if (day <= dayOfWeekStart) {
      date.setDate(date.getDate() - day + dayOfWeekStart);
    } else {
      date.setDate(date.getDate() + (dayOfWeekStart + 7) - day);
    }
    const dayOfMonth = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const fullYear = date.getFullYear();
  
    return `${dayOfMonth}/${month}/${fullYear}`; 
}


function getMonth(monthIndex)
{
    switch(monthIndex)
    {
        case 0:
            return 'JAN'
        case 1:
            return 'FEV'
        case 2:
            return 'MAR'
        case 3:
            return 'ABR'
        case 4:
            return 'MAI'
        case 5:
            return 'JUN'
        case 6:
            return 'JUL'
        case 7:
            return 'AGO'
        case 8:
            return 'SET'
        case 9:
            return 'OUT'
        case 10:
            return 'NOV'
        case 11:
            return 'DEZ'
    }
}

function sortJsonByDate(data) {
    return data.sort((a, b) => {
        const yearA = a.year, yearB = b.year;
        const monthA = a.month, monthB = b.month;

        // Compare by year first
        if (yearA < yearB) return -1;
        if (yearA > yearB) return 1;

        // If years are the same, compare by month
        if (monthA < monthB) return -1;
        if (monthA > monthB) return 1;

        // If both year and month are the same
        return 0;
    });
}

function buildLineData(doneData, goals, week, year, month) {
    var stdLineData = Object.create({})
    var index = null
    // stdLineData = Object.assign({}, {});
    console.log('\n\n\n\n\n\n\ninside build line data')
    console.log('doneData: ', doneData)
    console.log('goalsData: ', goals)

    var i = 0
    for (const done of doneData)
    {
        if(week == 0)
        {
            index = getMonth(done['month'])
        }
        else
        {
            index = getFirstDayOfWeek(done['week_number'], done['year'])
        }
        console.log('index: ', index)
        console.log('done[month]: ', done['month'])
        console.log('done[week_number]: ', done['week_number'])
        if(i>=goals.length)
        {
            i=goals.length-1
        }
        if(week != 0)
        {
            done['week_number'] = done['week_number']-1
            var month_index_week = Math.floor(done['week_number']/4.33)
            var goal = goals.filter((goal) => goal['month'] == month_index_week)[0]
            console.log('\n\n\n\nmonth_index_week: ', month_index_week)
        }
        else{
            var goal = goals[i]
        }
        const { inbound, outbound, venda } = buildStages(goal, done, week, month)
        inbound.forEach((stage) => {
            if(!stdLineData[stage['stage']])
            {
                stdLineData[stage['stage']] = []
            }
            stdLineData[stage['stage']].push({index: index, meta: stage['goal'], feito: stage['done']})
        })
        outbound.forEach((stage) => {
            if(!stdLineData[stage['stage']])
            {
                stdLineData[stage['stage']] = []
            }
            stdLineData[stage['stage']].push({index: index, meta: stage['goal'], feito: stage['done']})
        })
        venda.forEach((stage) => {
            if(!stdLineData[stage['stage']])
            {
                stdLineData[stage['stage']] = []
            }
            stdLineData[stage['stage']].push({index: index, meta: stage['goal'], feito: stage['done']})
            
        })
        i+=1
    }
    
    return stdLineData;
}

function getStageName(dealStageValue) {
  switch (dealStageValue) {
    case "18278955": return 'PRÉ-LEADS';
    case "appointmentscheduled": return 'Lead pré-qualificado';
    case "96967535": return 'LIGAÇÃO REALIZADA';
    case "qualifiedtobuy": return 'OPORTUNIDADE';
    case "86031677": return 'REUNIÃO MARCADA';
    case "16545556": return 'Lead Quente (há interesse)';
    case "decisionmakerboughtin": return 'FORMULÁRIO';
    case "presentationscheduled": return 'DUE DILIGENCE';
    case "closedwon": return 'PROPOSTA FEITA';
    case "90519382": return 'PROPOSTA ACEITA';
    case "90519383": return 'APROVADO NO COMITE';
    case "contractsent": return 'CONTRATO ASSINADO';
    case "16610672": return 'TRÂMITES JURÍDICOS';
    case "16610674": return 'NEGÓCIO CONCLUÍDO';
    case "21233795": return 'FUTURO INDEFINIDO';
    case "closedlost": return 'NEGÓCIO PERDIDO';
    case "26771109": return 'OPORTUNIDADE INBOUND';
    case "28853651": return 'Lead Quente (há interesse) INBOUND';
    case "26771110": return 'FORMULÁRIO INBOUND';
    case "26771111": return 'DUE DILIGENCE INBOUND';
    case "26771112": return 'PROPOSTA FEITA INBOUND';
    case "90526066": return 'PROPOSTA ACEITA INBOUND';
    case "90526067": return 'APROVADO NO COMITE INBOUND';
    case "26771113": return 'CONTRATO ASSINADO INBOUND';
    case "26771114": return 'TRÂMITES JURÍDICOS INBOUND';
    case "26957834": return 'FUTURO INDEFINIDO INBOUND';
    case "26957835": return 'NEGÓCIO PERDIDO INBOUND';
    case "71310377": return 'CONTRATO ASSINADO VENDAS';
    case "76619447": return 'ENVIO DE FORMULÁRIO';
    case "76619448": return 'PRODUÇÃO DE FOTOS';
    case "76619449": return 'ENVIO AOS PARCEIROS';
    case "76619520": return 'ANUNCIADO';
    case "76619521": return 'VISITA';
    case "76619522": return 'PROPOSTA';
    case "76701839": return 'NEGOCIAÇÃO';
    case "76701840": return 'VENDIDO';
    case "76701841": return 'FATURADO';
    case "76701842": return 'RECEBIDO';
    default: return 'UNKNOWN STAGE'; // Default case if no match found
  }
}

function getCurrentDateFormated()
{
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let hour = newDate.getHours();
    let minute = newDate.getMinutes();
    
    return `${date}/${month<10?`0${month}`:`${month}`}/${year} ${hour}:${minute}`
}

function SalesPipelines() {
  const [value, setValue] = React.useState(0);
  const [month, setMonth] = React.useState(5);
  const [year, setYear] = React.useState(2024);
  const [week, setWeek] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [searched, setSearched] = React.useState(false);
  const [lineData, setLineData] = React.useState(false)
  const [stagesInbound, setStagesInbound] = React.useState([])
  const [stagesOutbound, setStagesOutbound] = React.useState([])
  const [stagesVenda, setStagesVenda] = React.useState([])
  const [lastUpdate, setLastUpdate] = React.useState(getCurrentDateFormated());
  const [loadingUpdate, setLoadingUpdate] = React.useState(false);

  const handleClick = async (event) => {
    setLoading(true)
    var done = null
    if(week == 0)
    {
        done = await api.get(`hubspot-routes/get-deals-in-month?month=${month-1}&year=${year}`)
    }
    else
    {
        done = await api.get(`hubspot-routes/get-deals-in-week?week=${week}&month=${month}&year=${year}`)
    }
    console.log('\n\n\n done.data ==> ', done.data)

    var goals = await api.get(`hubspot-routes/get-goals-in-month?month=${month-1}&year=${year}`)

    var doneData = sortJsonByDate(done.data)
    var goalsData = sortJsonByDate(goals.data)

    var goal = goalsData[4]
    var data = doneData[4]
    console.log('\n\n\n\n\n\n goal ==> ', goal)
    console.log('data ==> ', data)
    
    const { inbound, outbound, venda } = buildStages(goal, data, week, month)

    const lineData = buildLineData(doneData, goalsData, week, year, month)
    console.log('lineData ==> ', lineData)

    setStagesInbound([...inbound])
    setStagesOutbound([...outbound])
    setStagesVenda([...venda])
    setLineData(lineData)
    
    setSearched(true)
    setLoading(false)  
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  const handleChangeWeek = (event) => {
    setWeek(event.target.value);
  };

  const update = async () => {
    console.log('update API -> ', api)
    await api.get('hubspot-routes/get-all')
    setLoadingUpdate(false)
  }

  const handleUpdate = (event) => {
    setLoadingUpdate(true)
    setLastUpdate(getCurrentDateFormated())
    update()
  }

  return (
    <Container maxWidth={false} marginRight="3%">
      <Paper>
        <div align="center">
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-helper-label">ANO</InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={year}
                    style={{"marginTop":"1%"}}
                    label="ANO"
                    onChange={handleChangeYear}
                >
                    <MenuItem value={2022}>2022</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                    <MenuItem value={2024}>2024</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-helper-label">MÊS</InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={month}
                    style={{"marginTop":"1%"}}
                    label="MÊS"
                    onChange={handleChangeMonth}
                >
                    <MenuItem value={1}>JAN</MenuItem>
                    <MenuItem value={2}>FEV</MenuItem>
                    <MenuItem value={3}>MAR</MenuItem>
                    <MenuItem value={4}>ABR</MenuItem>
                    <MenuItem value={5}>MAI</MenuItem>
                    <MenuItem value={6}>JUN</MenuItem>
                    <MenuItem value={7}>JUL</MenuItem>
                    <MenuItem value={8}>AGO</MenuItem>
                    <MenuItem value={9}>SET</MenuItem>
                    <MenuItem value={10}>OUT</MenuItem>
                    <MenuItem value={11}>NOV</MenuItem>
                    <MenuItem value={12}>DEZ</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                value={week}
                onChange={handleChangeWeek}
                style={{"marginTop":"1%"}}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value={0}>TOTAL</MenuItem>
                    <MenuItem value={1}>SEMANA #1</MenuItem>
                    <MenuItem value={2}>SEMANA #2</MenuItem>
                    <MenuItem value={3}>SEMANA #3</MenuItem>
                    <MenuItem value={4}>SEMANA #4</MenuItem>
                    <MenuItem value={5}>SEMANA #5</MenuItem>
                </Select>
            </FormControl>
            <LoadingButton loading={loading} size="large" style={{"marginTop":"1%"}} variant="contained" onClick={handleClick}>BUSCAR</LoadingButton>
            {/* <LoadingButton fontWeight="bold" loading={loadingUpdate} size="large" variant="contained" style={{"width":"30%", "marginTop":"1%", "marginLeft":"10%"}} color="secondary" onClick={handleUpdate}>
                    ATUALIZAR </LoadingButton> */}
            <Button href="/history" variant="contained" color="warning" style={{"marginLeft":"10%", "marginTop":"1%"}}>HISTÓRICO</Button>
        </div>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab style={{width:"30%"}} label="SOLVE INBOUND PIPELINE" />
          <Tab style={{width:"30%"}} label="SOLVE OUTBOUND PIPELINE" />
        </Tabs>
      </Paper>
      {searched ? 
        <PipelinePanel value={value} index={0} lineData={lineData} stagesInbound={stagesInbound} stagesOutbound={stagesOutbound} stagesVenda={stagesVenda}/>:
        <div align="center">
            <Typography style={{'marginTop':'10%'}} variant="h1">Realizar a busca</Typography>
        </div>
      }
    </Container>
  );
}

export default SalesPipelines;
