import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import api from '../../api/api';
import LoadingButton from '@mui/lab/LoadingButton';

function ConversionRateTable(props) {
    // Render each row of the table
    const [loading, setLoading] = React.useState(false);

    const rates = props.rates
    const volumes = props.volumes
    const pipeline = props.value
    const renderRows = () => {
        const stages = Object.keys(rates)
        return stages.map((stage) => {
            const conversionRate = rates[stage];
            const vol = volumes[stage];
            return (
                <TableRow key={stage}>
                    <TableCell>{stage}</TableCell>
                    <TableCell>{conversionRate}</TableCell>
                    <TableCell>{vol}</TableCell>
                </TableRow>
            );
        });
    };
    async function insertStandardConvRate(stage, conversionRate, pipeline)
    {
        await api.post('history/update-standard-conversion-rate', {
            stage: stage,
            conversionRate: parseFloat(conversionRate).toFixed(2),
            pipeline: pipeline
        })
    }

    const handleUpdate = async () => {
        setLoading(true)
        const stages = Object.keys(rates)
        var r = null
        stages.forEach(stage => {
            const conversionRate = rates[stage];
            insertStandardConvRate(stage, conversionRate, pipeline)
        })
        await api.post('history/update-current-monthly-goals')
        setLoading(false)
    };

    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Etapa</TableCell>
                            <TableCell>Taxa de conversão</TableCell>
                            <TableCell>Volume</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderRows()}
                    </TableBody>
                </Table>
            </TableContainer>
            <LoadingButton loading={loading} variant="contained" color="primary" onClick={handleUpdate} style={{ marginTop: '20px' }}>
                Mudar taxa de conversão do PIPELINE utilizada para previsão e metas 
            </LoadingButton>
        </div>
    );
}

export default ConversionRateTable;
