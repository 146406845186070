import React, { useState } from 'react';
import { Button, TextField, Typography, Container, Snackbar } from '@mui/material';
import api from '../api/api'

function Login(props) {
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);


  const handleChange = (e) => {
    const value = e.target.value;
    setPassword(value);
  };

  async function handleSubmit(e){
    e.preventDefault();
    var authenticated = await api.get(`hubspot-routes/authenticate?password=${password}`)
    if(authenticated.data)
    {
        props.authenticate(authenticated.data)
    }
    else
    {
        setOpen(true)
    }
  };

  return (
    <div align="center">
        <Container style={{"marginTop": "10%"}}>
            <Snackbar
                open={open}
                onClose={() => setOpen(false)}
                message="SENHA ERRADA"
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
            <form onSubmit={handleSubmit}>
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={handleChange}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    Login
                </Button>
            </form>
        </Container>
    </div>
  );
}

export default Login;
