import React from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, LabelList, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine } from 'recharts';

function DualYAxisLineGraph(props) {
    const data = props.data
    const maxRight = Math.ceil(Math.max(...data.map(item => item.convRate))*1.1)
    const maxLeft = Math.ceil(Math.max(...data.map(item => item.vol))*1.1);
    const totalDone = data.reduce((acc, curr) => acc + curr.vol, 0);
    const averageDone = totalDone / data.length;

    return (
        <LineChart
            width={1000}
            height={500}
            data={data}
            margin={{
                top: 15, right: 30, left: 20, bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="index" />
            <YAxis yAxisId="left" domain={[0, maxLeft]}/>
            <YAxis yAxisId="right" orientation="right" domain={[0, maxRight]} />
            <Tooltip />
            <Legend wrapperStyle={{ fontSize: '18px' }}/>
            <Line yAxisId="left" type="monotone" dataKey="vol" strokeWidth={4} stroke="#8884d8" name="Número de negócios">
                <LabelList dataKey="vol" position="top" stroke="#8884d8" offset={10}/>
            </Line>
            <Line yAxisId="right" type="monotone" dataKey="convRate" strokeWidth={4} stroke="#82ca9d" name="Taxa de conversão (%)">
                <LabelList dataKey="convRate" position="top" stroke="#82ca9d"/>
            </Line>

        </LineChart>
    );
};

DualYAxisLineGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    month: PropTypes.string.isRequired,
    vol: PropTypes.number.isRequired,
    convRate: PropTypes.number.isRequired,
  })).isRequired,
};

export default DualYAxisLineGraph;
