import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, Button, Container, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import api from '../../api/api';
import LoadingButton from '@mui/lab/LoadingButton';


const Forecasting = (props) => {
    const [value, setValue] = React.useState(0);
    const [leadTime, setLeadTime] = React.useState({});
    const [pipelineLeads, setPipelineLeads] = React.useState({});
    const [pipelineConvRate, setPipelineConvRate] = React.useState({});
    const [pipelineConversionRateToLastStage, setPipelineConversionRateToLastStage] = React.useState({});
    const [pipelineLeadtimeToLastStage, setPipelineLeadtimeToLastStage] = React.useState({});
    const [pipelineDealsInEachStage, setPipelineDealsInEachStage] = React.useState({});

    const [show, setShow] = React.useState(false);
    const [expectedClosedDeals, setExpectedClosedDeals] = React.useState([]);
    const [loading, setLoading] = React.useState(false);


    const conversionRateToLastStage = {}
    const leadtimeToLastStage = {}
    const dealsInTheStage = {}

    const calculateExpectedClosedDeals = () => {
        let dealsInTheStageForThePipeline = pipelineDealsInEachStage[value]
        let leadtimeToLastStageForThePipeline = pipelineLeadtimeToLastStage[value]

        const today = new Date()
        const currentMonth = today.getMonth()
        const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OUT", "NOV", "DEC"];
        const expectedClosedDeals = {};
    
        for (let stage in dealsInTheStageForThePipeline) {
            if (stage === "CONTRATO ASSINADO" || stage === "CONTRATO ASSINADO INBOUND") continue;
            const deals = parseFloat(dealsInTheStageForThePipeline[stage]);
            const leadTime = parseFloat(leadtimeToLastStageForThePipeline[stage]);
            const closingMonth = Math.floor((today.getDate() + leadTime) / 30);

            const monthIndex = (currentMonth + closingMonth) % 12;

            if (!expectedClosedDeals[months[monthIndex]])
            {
                expectedClosedDeals[months[monthIndex]] = 0
            }
    
            expectedClosedDeals[months[monthIndex]] += deals;
        }
        setExpectedClosedDeals(expectedClosedDeals);
    }

    function transformArray(arr, content) {
        console.log('arr ========> \n ', arr)
        let result = {
            0: {
                'OPORTUNIDADE INBOUND': 0,
                'Lead Quente (há interesse) INBOUND': 0,
                'FORMULÁRIO INBOUND': 0,
                'DUE DILIGENCE INBOUND': 0,
                'PROPOSTA FEITA INBOUND': 0,
                'CONTRATO ASSINADO INBOUND': 0
            },
            1: {
                'PRÉ-LEADS': 0,
                'Lead pré-qualificado': 0,
                'OPORTUNIDADE': 0,
                'REUNIÃO MARCADA': 0,
                'Lead Quente (há interesse)': 0,
                'FORMULÁRIO': 0,
                'DUE DILIGENCE': 0,
                'PROPOSTA FEITA': 0,
                'CONTRATO ASSINADO': 0
            },
            2: {
                'CONTRATO ASSINADO VENDAS': 0,
                'ENVIO DE FORMULÁRIO': 0,
                'PRODUÇÃO DE FOTOS': 0,
                'ENVIO AOS PARCEIROS': 0,
                'ANUNCIADO': 0,
                'VISITA': 0,
                'PROPOSTA': 0,
                'NEGOCIAÇÃO': 0,
                'VENDIDO': 0,
                'FATURADO': 0,
                'RECEBIDO': 0
            }
        }
      
        arr.forEach(item => {
          if (!result[item.pipeline]) {
            result[item.pipeline] = {};
          }
      
          result[item.pipeline][item.stage] = item[content];
        });

        return result;
    }

    async function updateData()
    {
        setLoading(true)
        var leads = (await api.get('/hubspot-routes/get-deals-in-each-stage')).data
        console.log('leads --------->>> ', leads)
        var convRate = (await api.get('/history/get-conv-rate')).data
        console.log('convRate --------->>> ', convRate)
        var conversionRate = transformArray(convRate, 'conversion_rate')
        const leadtimeData = (await api.get('/history/get-leadtime')).data
        console.log('leadtimeData --------->>> ', leadtimeData)
        var leadtimeDict = transformArray(leadtimeData, 'leadtime')
        console.log('conversionRate ------>>> ', conversionRate)

        const pipelines = Object.keys(leads)
        console.log('pipelines: ', pipelines)
        for (const pipeline of pipelines) {
            conversionRateToLastStage[pipeline] = {}
            leadtimeToLastStage[pipeline] = {}
            dealsInTheStage[pipeline] = {}
            var i = 0
            for (let stage in conversionRate[pipeline]) {
                console.log('stage: ', stage)
                let finalRate = 1
                let finalTime = 0
                var leadsInStage = leads[pipeline][stage]
                var j = 0
                for (let substage in conversionRate[pipeline])
                {
                    let rate = conversionRate[pipeline][substage]/100
                    let time = leadtimeDict[pipeline][substage]
                    if(j>=i && substage != "CONTRATO ASSINADO" && substage != "CONTRATO ASSINADO INBOUND")
                    {
                        finalTime += time
                        finalRate *= rate
                    }
                    j+=1
                }
                console.log('\n\n\n\n\n')
                console.log('pipeline: ', pipeline)
                console.log('stage: ', stage)
                console.log('finalRate: ', finalRate)
                console.log('finalTime: ', finalTime)
                console.log('leadsInStage: ', leadsInStage)
                console.log('\n\n\n\n\n')

                leadtimeToLastStage[pipeline][stage] = finalTime.toFixed(2)
                conversionRateToLastStage[pipeline][stage] = (finalRate*100).toFixed(2)
                dealsInTheStage[pipeline][stage] = (leadsInStage*finalRate).toFixed(2)
                i+=1
            }
    
        }

        console.log('------------>>> leads: ', {...leads})
        console.log('conversionRate: ', {...conversionRate})
        console.log('leadtimeDict: ', {...leadtimeDict})

    
        setPipelineLeads({...leads})
        setPipelineConvRate({...conversionRate})
        setLeadTime({...leadtimeDict})
        setPipelineConversionRateToLastStage({...conversionRateToLastStage})
        setPipelineLeadtimeToLastStage({...leadtimeToLastStage})
        setPipelineDealsInEachStage({...dealsInTheStage})

        setLoading(false)
        setShow(true)
    }

    const handleChange = (event) => {
        setValue(event.target.value);
        setExpectedClosedDeals([]);
        
    };

    return (
        <Container>
            <FormControl sx={{ m: 1, minWidth: 120, width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">PIPELINE</InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={value}
                    label="PIPELINE"
                    onChange={handleChange}
                >
                    <MenuItem value={0}>SOLVE INBOUND PIPELINE</MenuItem>
                    <MenuItem value={1}>SOLVE OUTBOUND PIPELINE</MenuItem>
                    {/* <MenuItem value={2}>SOLVE VENDA DE IMÓVEIS PIPELINE</MenuItem> */}
                </Select>
            </FormControl>
            <LoadingButton loading={loading} onClick={updateData} type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
                BUSCAR DADOS ATUALIZADOS DE TAXA DE CONVERSÃO, LEADTIME, E PIPELINE ATUALIZADO
            </LoadingButton>
            {show ?
            <div>
                {/* {pipelineLeads} */}
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Etapa</TableCell>
                                <TableCell>Número de leads no pipeline</TableCell>
                                <TableCell>Tx. de conv.</TableCell>
                                <TableCell>Tx. de conv até o fim</TableCell>
                                <TableCell>Leadtime</TableCell>
                                <TableCell>Tempo até fechamento</TableCell>
                                <TableCell>Contratos assinados de acordo com tx. conv.</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(pipelineLeads[value]).map((stage, index) => (
                                <TableRow key={stage}>
                                    <TableCell>{stage}</TableCell>
                                    <TableCell>{pipelineLeads[value][stage]}</TableCell>
                                    <TableCell>{pipelineConvRate[value][stage]}</TableCell>
                                    <TableCell>{pipelineConversionRateToLastStage[value][stage]}</TableCell>
                                    <TableCell>{leadTime[value][stage]}</TableCell> 
                                    <TableCell>{pipelineLeadtimeToLastStage[value][stage]}</TableCell>
                                    {stage == "CONTRATO ASSINADO" || stage == "CONTRATO ASSINADO INBOUND" ? <TableCell>-</TableCell>
                                    :
                                    <TableCell>{pipelineDealsInEachStage[value][stage]}</TableCell>}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button onClick={calculateExpectedClosedDeals} type="submit" variant="contained" color="primary" style={{ marginTop: '20px', marginBottom: '20px' }}>
                    CALCULAR PREVISÃO
                </Button>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>MÊS</TableCell>
                                <TableCell>NÚMERO PREVISTO DE CONTRATOS ASSINADOS</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(expectedClosedDeals).map((month, index) => (
                                <TableRow key={month}>
                                    <TableCell>{month}</TableCell>
                                    <TableCell>{(expectedClosedDeals[month]).toFixed(2)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            :null}
        </Container>
    );
};

export default Forecasting;