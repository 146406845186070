import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { red, green, lightBlue } from '@mui/material/colors';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import PieGraph from './PieGraph.js'
import LineGraph from './LineGraph.js'
import api from '../../api/api'


function setDoneAndGoal(pipeline, stagesInbound, stagesOutbound, stagesVenda, step)
{
    var stageData = null
    if (pipeline == 0)
    {
        stageData = stagesInbound[step]
    }
    else if (pipeline == 1)
    {
        stageData = stagesOutbound[step]
    }
    else
    {
        stageData = stagesVenda[step]
    }
    return {goal: stageData.goal, done: stageData.done}
}


function setSteps(pipeline, stagesInbound, stagesOutbound, stagesVenda){
    if(pipeline == 0)
    {
        return stagesInbound
    }
    else if(pipeline == 1)
    {
        return stagesOutbound
    }
    else
    {
        return stagesVenda
    }
}

function PipelinePanel(props) {
    const [activeStep, setActiveStep] = React.useState(0);

    const stagesInbound = props.stagesInbound
    const stagesOutbound = props.stagesOutbound
    const stagesVenda = props.stagesVenda
    const lineData = props.lineData

    const pipeline = props.value;
    const steps = setSteps(pipeline, props.stagesInbound, props.stagesOutbound, props.stagesVenda)
    console.log('\n\n\n\n\nsteps => ', steps)
    var obj = setDoneAndGoal(pipeline, props.stagesInbound, props.stagesOutbound, props.stagesVenda, 0)

    const [goal, setGoal] = React.useState(obj.goal);
    const [done, setDone] = React.useState(obj.done);
    const [stageName, setStageName] = React.useState(false);

    const handleStep = (step, stage) => () => {
        setStageName(stage)
        setActiveStep(step);
        var obj = setDoneAndGoal(pipeline, stagesInbound, stagesOutbound, stagesVenda, step)
        setDone(obj.done)
        setGoal(obj.goal)
    };

    const setBorder = (index) => {
        return index == activeStep ? 2:0
    }

    const setColor = (done, goal) => {
        var made = 100*done/goal
        if(made <= 50)
        {
            if(made >= 45)
            {
                return red[50]
            }
            var transform_percent_to_scale = Math.round(1000 - made*20)
            var real_scale = transform_percent_to_scale - transform_percent_to_scale%100
            return red[real_scale]
        }
        else
        {
            if(made <= 55)
            {
                return green[50]
            }
            var transform_percent_to_scale = Math.round((made-50)*20)
            var real_scale = transform_percent_to_scale - transform_percent_to_scale%100
            if(real_scale > 900){
                return lightBlue[500]
            }
            return green[real_scale]
        }
    }


    return (
        <Box sx={{ width: '100%'}}>
            
            <Stepper nonLinear activeStep={activeStep} style={{'marginRight':'2%', 'marginTop':'1%'}}>
            {steps.map((info, index) => (
                <Step key={index}>
                <div align="center" style={{ marginLeft: '5%' }}>
                        {info.stage}
                </div>
                <br/>
                <StepButton  onClick={handleStep(index, info.stage)} sx={{bgcolor: setColor(info.done, info.goal),
                                                                borderColor: 'info.main',
                                                                border: setBorder(index),
                                                                boxShadow: 1,
                                                                borderRadius: 2,
                                                                margin: 0.1}}>
                </StepButton>
                <div align="center">
                    <br/>
                    {Math.round(100*info.done/info.goal)}%
                </div>
                </Step>
            ))}
            </Stepper>
            {stageName ?
                <div style={{ display: 'flex', marginLeft: '8%' }}>
                    <PieGraph done={done} goal={goal}/>
                    <Typography style={{'marginLeft':'5%','marginRight':'5%', 'marginTop':'5%'}} variant="h3">{Math.round(100*done/goal)}%</Typography>
                    <LineGraph data={lineData[stageName]} />
                </div>
                :null
            }
        </Box>
    );
}

export default PipelinePanel;