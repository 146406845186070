import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ClosedDealsGoal from '../../components/history/ClosedDealsGoal';
import UpdateConvRates from '../../components/history/UpdateConvRates';
import ConversionRates from '../../components/history/ConversionRates';
import Forecasting from '../../components/history/Forecasting';
import api from '../../api/api'
import LoadingButton from '@mui/lab/LoadingButton';

const transformColumnName = column => {
    var column = column.replace('column_','')
    switch (column) {
        case "18278955": return 'PRÉ-LEADS';
        case "appointmentscheduled": return 'Lead pré-qualificado';
        case "96967535": return 'LIGAÇÃO REALIZADA';
        case "qualifiedtobuy": return 'OPORTUNIDADE';
        case "86031677": return 'REUNIÃO MARCADA';
        case "16545556": return 'Lead Quente (há interesse)';
        case "decisionmakerboughtin": return 'FORMULÁRIO';
        case "presentationscheduled": return 'DUE DILIGENCE';
        case "closedwon": return 'PROPOSTA FEITA';
        case "90519382": return 'PROPOSTA ACEITA';
        case "90519383": return 'APROVADO NO COMITE';
        case "contractsent": return 'CONTRATO ASSINADO';
        case "21233795": return 'FUTURO INDEFINIDO';
        case "closedlost": return 'NEGÓCIO PERDIDO';
        case "26771109": return 'OPORTUNIDADE INBOUND';
        case "28853651": return 'Lead Quente (há interesse) INBOUND';
        case "26771110": return 'FORMULÁRIO INBOUND';
        case "26771111": return 'DUE DILIGENCE INBOUND';
        case "26771112": return 'PROPOSTA FEITA INBOUND';
        case "90526066": return 'PROPOSTA ACEITA INBOUND';
        case "90526067": return 'APROVADO NO COMITE INBOUND';
        case "26771113": return 'CONTRATO ASSINADO INBOUND';
        case "26957834": return 'FUTURO INDEFINIDO INBOUND';
        case "26957835": return 'NEGÓCIO PERDIDO INBOUND';
        case "71310377": return 'CONTRATO ASSINADO VENDAS';
        case "76619447": return 'ENVIO DE FORMULÁRIO';
        case "76619448": return 'PRODUÇÃO DE FOTOS';
        case "76619449": return 'ENVIO AOS PARCEIROS';
        case "76619520": return 'ANUNCIADO';
        case "76619521": return 'VISITA';
        case "76619522": return 'PROPOSTA';
        case "76701839": return 'NEGOCIAÇÃO';
        case "76701840": return 'VENDIDO';
        case "76701841": return 'FATURADO';
        case "76701842": return 'RECEBIDO';
        default: return 'UNKNOWN';
    }
};

export default function History() {
    const [value, setValue] = React.useState('1');
    const [loading, setLoading] = React.useState(false);

    function groupLeadsByTimePeriod(leads) {
        const result = {
            0: {
                yearly: {},
                halfYearly: {},
                quarterly: {},
                monthly: {}
            },
            1: {
                yearly: {},
                halfYearly: {},
                quarterly: {},
                monthly: {}
            },
            2: {
                yearly: {},
                halfYearly: {},
                quarterly: {},
                monthly: {}
            }
        };
    
        const pipelines = [
            [
                'OPORTUNIDADE INBOUND',
                'Lead Quente (há interesse) INBOUND',
                'FORMULÁRIO INBOUND',
                'DUE DILIGENCE INBOUND',
                'PROPOSTA FEITA INBOUND',
                'CONTRATO ASSINADO INBOUND'
            ],
            [
                'PRÉ-LEADS',
                'Lead pré-qualificado',
                'OPORTUNIDADE',
                'Lead Quente (há interesse)',
                'FORMULÁRIO',
                'DUE DILIGENCE',
                'PROPOSTA FEITA',
                'CONTRATO ASSINADO'
            ],
            [
                'CONTRATO ASSINADO VENDAS',
                'ENVIO DE FORMULÁRIO',
                'PRODUÇÃO DE FOTOS',
                'ENVIO AOS PARCEIROS',
                'ANUNCIADO',
                'VISITA',
                'PROPOSTA',
                'NEGOCIAÇÃO',
                'VENDIDO',
                'FATURADO',
                'RECEBIDO'
            ]
        ];
        
    
        const getQuarter = month => Math.floor((month) / 3) + 1;
        const getHalfYear = month => (month <= 5 ? 1 : 2);
    
        leads.forEach(lead => {
        const year = lead.year;
        const quarter = getQuarter(lead.month);
        const halfYear = getHalfYear(lead.month);
    
            pipelines.forEach((pipeline, pipelineIndex) => {
                const updateCounts = (period, key) => {
                    const keyWithPipeline = `${key}`;
                    if (!result[pipelineIndex][period][keyWithPipeline]) {
                        result[pipelineIndex][period][keyWithPipeline] = { conversionRates: [], stageVolumes: {} };
                    }
                    const currentStageCounts = {};
                    pipeline.forEach((stage, index) => {
                        const column = Object.keys(lead).find(col => stage === transformColumnName(col));
                        currentStageCounts[stage] = (currentStageCounts[stage] || 0) + (lead[column] || 0);
                        result[pipelineIndex][period][keyWithPipeline].stageVolumes[stage] = (result[pipelineIndex][period][keyWithPipeline].stageVolumes[stage] || 0) + (lead[column] || 0);
                    });
                };
                
                updateCounts('yearly', year);
                updateCounts('halfYearly', `${year}-H${halfYear}`);
                updateCounts('quarterly', `${year}-Q${quarter}`);
                updateCounts('monthly', `${year}-${lead.month}`);
            });
        });
        const pipelineIndexs = Object.keys(result)
        pipelineIndexs.forEach(pipelineIndex => {
            const periods = Object.keys(result[pipelineIndex])
            periods.forEach(period => {
                const keys = Object.keys(result[pipelineIndex][period])
                keys.forEach(key => {
                    const stages = Object.keys(result[pipelineIndex][period][key].stageVolumes)
                    stages.forEach((stage, index) => {
                        if(index == stages.length-1)
                        {
                            var stageVol = result[pipelineIndex][period][key].stageVolumes[stage]
                            var index = 0
                            if(pipelineIndex == 1)
                            {
                                index = 2
                            }
                            var firstStageVol = result[pipelineIndex][period][key].stageVolumes[pipelines[pipelineIndex][index]]
                            if(firstStageVol == 0)
                            {
                                result[pipelineIndex][period][key].conversionRates[stage] = 0.0
                            }
                            else
                            {
                                let conversionRate = (100*stageVol / firstStageVol).toFixed(1)
                                result[pipelineIndex][period][key].conversionRates[stage] = conversionRate
                            }
                        }
                        else
                        {
                            var nextStage = stages[index+1]
                            var stageVol = result[pipelineIndex][period][key].stageVolumes[stage]
                            if(stageVol == 0)
                            {
                                result[pipelineIndex][period][key].conversionRates[stage] = 0.0
                            }
                            else
                            {
                                var nextStageVol = result[pipelineIndex][period][key].stageVolumes[nextStage]
                                let conversionRate = (100*nextStageVol / stageVol).toFixed(1)
                                result[pipelineIndex][period][key].conversionRates[stage] = conversionRate
                            }
                        }
                    })
                })
            })
        })

        return result;
    }

    const [result, setResult] = React.useState({})
    const [searched, setSearched] = React.useState(false);

    const handleClick = async () => {
        setLoading(true)
        var leads = await api.get('history/get-deals')
        leads = leads["data"]
        
       
        const result = await groupLeadsByTimePeriod(leads)
    
        setResult(result)
        
        setSearched(true)
        setLoading(false)  
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Container style={{maxWidth: "100%"}}>
            <TabContext value={value} style={{width: "100%"}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "100%" }}>
                    <div align="center">
                        <LoadingButton loading={loading} size="large" style={{width: "60%", "marginTop":"1%", "marginBottom":"1%"}} variant="contained" onClick={handleClick}>LER HISTÓRICO</LoadingButton>
                    </div>
                    <TabList style={{width: "100%"}} onChange={handleChange} aria-label="lab API tabs example">
                        <Tab style={{width: "25%"}} label="HISTÓRICO TX. CONV E VOL" value="1" />
                        <Tab style={{width: "25%"}} label="PREVISÃO" value="2" />
                        <Tab style={{width: "25%"}} label="META CONTRATOS ASSINADOS" value="3" />
                        <Tab style={{width: "25%"}} label="ATUALIZAR TX. CONV" value="4" />
                    </TabList>
                </Box>

                {searched ?
                    <div>
                        <TabPanel value="1"><ConversionRates result={result} searched={searched}/></TabPanel>
                        <TabPanel value="2"><Forecasting/></TabPanel>
                        <TabPanel value="3"><ClosedDealsGoal/></TabPanel>
                        <TabPanel value="4"><UpdateConvRates result={result}/></TabPanel>
                    </div>
                    :
                    <div align="center">
                        <Typography style={{'marginTop':'10%'}} variant="h1">Realizar a busca</Typography>
                    </div>
                }
            </TabContext>
        </Container>
    );
}