import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ConversionRateTable from './ConversionRateTable'

const UpdateConvRates = (props) => {
    const years = [2022, 2023, 2024];
    const [value, setValue] = React.useState(0);

    const result = props.result

    const [yearInit, setYearInit] = useState(2024);
    const [monthInit, setMonthInit] = useState(0);
    const [yearEnd, setYearEnd] = useState(2024);
    const [monthEnd, setMonthEnd] = useState(0);

    const [loading, setLoading] = React.useState(false);
    const [convRates, setConvRates] = React.useState(false);
    const [volumes, setVolumes] = React.useState(false);
       
    function calculateConversionRates(data, startYear, startMonth, endYear, endMonth) {

        let accumulatedStages = null;

        for (let key in (data)){
            var month = parseInt(key.split('-')[1])
            var year = parseInt(key.split('-')[0])
            //logical expression to check if the date is between the start and end date
            var isAfterStart = (month >= startMonth && year >= startYear)
            var isBeforeEnd = (year < endYear) || (year == endYear && month <= endMonth)
            if (isAfterStart && isBeforeEnd) {
                if (accumulatedStages === null)
                {
                    accumulatedStages = {...data[key].stageVolumes};
                }
                else
                {
                    for (let stage in data[key].stageVolumes)
                    {
                        accumulatedStages[stage] += data[key].stageVolumes[stage];
                    }
                }
            }
        }
        
        if (accumulatedStages === null) {
            throw new Error("Invalid date range");
        }

        let conversionRates = {};
        let volumes = {};
        let stages = Object.keys(accumulatedStages);
        for (let i = 0; i < stages.length; i++) {
            if(i == (stages.length - 1)) {
                if(value == 1) {
                    conversionRates[`${stages[i]}`] = (100*accumulatedStages[stages[i]] / accumulatedStages[stages[1]]).toFixed(2);
                }
                else {
                    conversionRates[`${stages[i]}`] = (100*accumulatedStages[stages[i]] / accumulatedStages[stages[0]]).toFixed(2);
                }
                volumes[`${stages[i]}`] = accumulatedStages[stages[i]]
            }
            else {
                if (accumulatedStages[stages[i]] === 0) {
                    conversionRates[`${stages[i]}`] = 0;
                    volumes[`${stages[i]}`] = 0
                } else {
                    conversionRates[`${stages[i]}`] = (100*accumulatedStages[stages[i+1]] / accumulatedStages[stages[i]]).toFixed(2);
                    volumes[`${stages[i]}`] = accumulatedStages[stages[i]]
                }
            }
        }

        setConvRates(conversionRates)
        setVolumes(volumes)
    }

    const handleClick = () => {
        setLoading(true)
        var monthlyPipeline = result[value]['monthly']

        calculateConversionRates(monthlyPipeline, yearInit, monthInit, yearEnd, monthEnd)
        setLoading(false)
    };

    const handleChange = (event) => {
        setValue(event.target.value)
    }

    const handleYearInitChange = (e) => setYearInit(e.target.value);
    const handleYearEndChange = (e) => setYearEnd(e.target.value);

    const handleMonthInitChange = (e) => setMonthInit(e.target.value);
    const handleMonthEndChange = (e) => setMonthEnd(e.target.value);

    const monthOptions = {0:'JAN', 1:'FEV', 2:'MAR', 3:'ABR', 4:'MAI', 5:'JUN', 6:'JUL', 7:'AGO', 8:'SET', 9:'OUT', 10:'NOV', 11:'DEZ'}

    return (
        <div  align="center">
            <div style={{display: "flex"}}>
                <Grid container spacing={2} style={{"width":"80%"}}>
                    <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-helper-label">PIPELINE</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={value}
                                style={{"marginTop":"1%"}}
                                label="PIPELINE"
                                onChange={handleChange}
                            >
                                <MenuItem value={0}>SOLVE INBOUND PIPELINE</MenuItem>
                                <MenuItem value={1}>SOLVE OUTBOUND PIPELINE</MenuItem>
                                <MenuItem value={2}>SOLVE VENDA DE IMÓVEIS PIPELINE</MenuItem>
                            </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel  id="demo-simple-select-helper-label">ANO INÍCIO</InputLabel>
                        <Select 
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            style={{"marginTop":"2%"}} 
                            value={yearInit}
                            label="ANO"
                            onChange={handleYearInitChange}
                        >
                        {years.map((y) => (
                            <MenuItem key={y} value={y}>
                                {y}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                        <InputLabel>MÊS INÍCIO</InputLabel>
                        <Select label="MÊS" style={{"marginTop":"2%"}} value={monthInit} onChange={handleMonthInitChange}>
                            {Object.keys(monthOptions).map((m) => (
                                <MenuItem key={m} value={m}>
                                    {monthOptions[m]}
                                </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel  id="demo-simple-select-helper-label">ANO FIM</InputLabel>
                        <Select 
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            style={{"marginTop":"2%"}} 
                            value={yearEnd}
                            label="ANO"
                            onChange={handleYearEndChange}
                        >
                        {years.map((y) => (
                            <MenuItem key={y} value={y}>
                                {y}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                        <InputLabel>MÊS FIM</InputLabel>
                            <Select label="MÊS" style={{"marginTop":"2%"}} value={monthEnd} onChange={handleMonthEndChange}>
                                {Object.keys(monthOptions).map((m) => (
                                    <MenuItem key={m} value={m}>
                                        {monthOptions[m]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <LoadingButton loading={loading} size="large" variant="contained" style={{"marginLeft":"3%"}} onClick={handleClick}>BUSCAR TAXA DE CONVERSÃO</LoadingButton>
            </div>
            <div align="center">
                {convRates ? 
                    <ConversionRateTable rates={convRates} volumes={volumes} value={value}/>
                    :
                    <div align="center">
                        <Typography style={{'marginTop':'10%'}} variant="h1">Buscar taxa de conversão</Typography>
                    </div>
                }
            </div>
        </div>
    );
};

export default UpdateConvRates;
