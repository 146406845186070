import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { Typography } from '@mui/material';

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function PieGraph(props) {
    const { done, goal, value, index } = props;
    const data = [
        { name: 'Á fazer', value: goal-done },
        { name: 'Feito', value: done },
    ];


    // You can customize the colors here
    const COLORS = ['#0088FE', '#00C49F'];

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            align="center"
            id={`simple-tabpanel-${index}`}
        >
            {value === index && (
            <PieChart width={550} height={450}>
                <Pie
                data={data}
                cx={230}
                cy={240}
                innerRadius={60}
                outerRadius={180}
                fill="#8884d8"
                label
                >
                {data.map((entry, index) => (
                    <Cell style={{ fontSize: '28px' }} key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
                </Pie>
                <text x={235} y={240} dy={12} textAnchor="middle" fill="#000" fontWeight="bold" fontSize="32">
                {goal}
                </text>
                <Tooltip />
                <Legend wrapperStyle={{ fontSize: '16px' }} />
            </PieChart>
            )}
        </div>
    );
}

export default PieGraph;